html {
  height: 100vh;
  width: 100vw;
}

body {
  margin: 0;
  padding: 0;
  overflow-y: hidden;
}

#root {
  position: relative;
  height: 100vh;
  width: 100vw;
  font-family: 'Cairo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}